.GetInTouch {
	max-width: 950px;
	margin: 0 auto 12rem;
	text-align: center;
	display: flex;
	justify-content: flex-end;

	@media (max-width: $desktop) {
		padding: 0 3rem;
		justify-content: center;
	}

	.inner-wrapper {
		width: 80%;
	}
	h3 {
		text-transform: uppercase;
		font-size: 1.3rem;
		span {
			color: $green;
			font-weight: normal;
			margin-right: 0.5rem;
		}
	}

	p {
		max-width: 550px;
		margin: 2rem auto;
		line-height: 1.5;
		font-weight: 300;
		color: #8892b0;
		font-size: 1.2rem;

		@media (max-width: $mobile) {
			font-size: 0.9rem;
			max-width: 300px;
		}
	}

	.email-me {
		font-weight: 300;
		margin-top: 2.5rem;
		@media (max-width: $med-desktop) {
			font-size: 1.1rem;
		}
		@media (max-width: $med-desktop) {
			font-size: 0.9rem;
		}

		a {
			border: 1px solid $green;
			color: $green;
			border-radius: 3px;
			padding: 0.7rem 1.5rem;
			transition: background 0.4s ease;

			&:hover {
				background: rgba(100, 255, 218, 0.1);
			}

			@media (max-width: $mobile) {
				font-size: 0.8rem;
				padding: 0.5rem 1.3rem;
			}
		}
	}
}
