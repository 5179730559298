.Navbar {
	@include flex(flex-end);
	align-items: center;
	height: 6vh;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 0 2rem;
	transition: all 0.5s ease;
	z-index: 100;

	@media (max-width: $mobile) {
		height: 9vh;
	}

	@media (max-width: $extra-sm) {
		height: 10vh;
	}

	.darkmode {
		display: flex;
		align-items: center;

		.day-icon {
			font-size: 1.1rem;
			padding-top: 0.4rem;
		}
	}
	.logo {
		height: auto;
		width: auto;
		max-height: 72px;
		max-width: 250px;
		position: fixed;

		top: 10px;
		left: 30px;
		object-fit: contain;
	}
	img {
		max-height: 35px;
	}

	.nav-links {
		a {
			cursor: pointer;
			font-weight: 300;
			margin-right: 2rem;
			color: $second-white;
			text-transform: capitalize;
			font-size: 0.9rem;
			transition: color 0.5s ease;
			&:last-child {
				margin: 0;
				margin-top: 1rem;
				//background-color: transparent;
				border: 1px solid rgb(255, 77, 0);
				border-radius: 1rem;
				padding: 0.5rem 1rem;
				line-height: 1;
			}
			&:hover {
				color: $green;
			}
			span {
				margin-right: 0.5rem;
				color: $green;
			}
		}

		@media (max-width: $laptop) {
			display: none;
		}
	}
}
.wrapper {
	height: 100vh;

	@media (max-width: $lg-mobile) {
		height: 60vh;
	}
	@media (max-width: $tablet) {
		height: 95vh;
	}

	@media (max-width: $sm-mobile) {
		height: 95vh;
	}

	.goup {
		font-size: 1.8rem;
		position: fixed;
		cursor: pointer;
		right: 1rem;
		bottom: 1rem;
		display: none;

		@media (max-width: $lg-mobile) {
			font-size: 1.5rem;
		}
	}

	.showHand {
		display: block;
	}
}
.Header {
	max-width: 950px;
	margin: 0 auto;
	display: flex;
	height: 100%;
	align-items: center;

	@media (max-width: $desktop) {
		max-width: 1000px;
	}

	@media (max-width: $fix-header) {
		max-width: 800px;
	}

	@media (max-width: $med-desktop) {
		max-width: 700px;
		padding: 0 1rem;
	}

	@media (max-width: $tablet) {
		margin: 0 auto 4rem;
	}

	@media (max-width: $sm-mobile) {
		margin: 0 auto 3rem;
	}

	@media (max-width: $extra-sm) {
		margin: 0 auto;
	}

	.Header-wrapper {
		width: 100%;
		letter-spacing: 1px;

		@media (max-width: $desktop) {
			padding: 0 2rem;
			margin-bottom: 2rem;
		}

		.say-hi {
			margin-bottom: 1rem;
			font-weight: 300;
			color: $green;

			@media (max-width: $mobile) {
				font-size: 0.9rem;
				margin-bottom: 0;
			}
		}

		.aboutme {
			h1 {
				&:first-child {
					color: $second-white;
				}
			}

			.what-ido {
				color: $dark-white;
				color: $darker-white;
			}
		}
	}
	h1 {
		font-weight: lighter;
		font-size: 4rem;
		font-weight: bold;

		@media (max-width: $med-desktop) {
			font-size: 2.5rem;
			margin: 1.5rem auto;
		}

		@media (max-width: $tablet) {
			margin-bottom: 2rem;
		}

		@media (max-width: $sm-mobile) {
			margin-bottom: 3rem;
		}

		@media (max-width: $mobile) {
			font-size: 1.6rem;
		}
		@media (max-width: $extra-sm) {
			font-size: 1.4rem;
			margin: 1rem auto;
		}

		.name {
			font-weight: bold;
			text-transform: capitalize;
		}
	}

	.about-description {
		line-height: 1.5;
		max-width: 450px;
		margin: 1.5rem 0 2.5rem;
		font-weight: 300;
		color: #8892b0;

		@media (max-width: $mobile) {
			font-size: 0.9rem;
			max-width: 300px;
			margin: 1rem 0 2rem;
		}
	}
	.email-me {
		font-weight: 300;
		@media (max-width: $med-desktop) {
			font-size: 1.1rem;
		}
		@media (max-width: $med-desktop) {
			font-size: 0.9rem;
		}

		a {
			border: 1px solid $green;
			color: $green;
			border-radius: 3px;
			padding: 0.7rem 1.5rem;
			transition: background 0.4s ease;

			&:hover {
				background: rgba(100, 255, 218, 0.1);
			}

			@media (max-width: $mobile) {
				font-size: 0.8rem;
				padding: 0.5rem 1.3rem;
				margin-bottom: 4rem;
			}
		}

		p {
			span {
				margin-left: 1rem;
				border-bottom: 2px solid $blue;
				cursor: pointer;
				@media (max-width: $med-desktop) {
					margin-left: 0.5rem;
				}
			}
		}
	}
}

.black {
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3);
	backdrop-filter: blur(10px);
}

.global-blur {
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.3);
	backdrop-filter: blur(20px);
}
