$blue: rgb(63, 98, 196);

$white: #f4f4f4;
$black: #333;
$green: #ff0000;

$dark-white: #ff0000;
$second-white: #ccd6f6;

$darker-white: #8892b0;

$desktop: 1600px;
$med-desktop: 1000px;
$fix-header: 1100px;
$sm-desktop: 800px;
$lg-mobile: 600px;
$mobile: 500px;
$tablet: 414px;
$sm-mobile: 375px;
$extra-sm: 320px;

$s-laptop: 900px;
$m-laptop: 850px;
$laptop: 800px;
$lg-phone: 650px;
$med-phone: 500px;
$galaxy: 414px;
