@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Raleway:wght@300;400;600&display=swap');
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: 'Open Sans', sans-serif;
	background: #162447;
	color: $second-white;
}

a {
	text-decoration: none;
	display: inline-block;
}

ul li {
	list-style: none;
}

span {
	display: inline-block;
}

.Parent-wrapper {
	color: #333;
	a {
		color: #333;
	}
}

a {
	color: $white;
}

.Header-wrapper {
	.aboutme {
		h1 {
			color: #f5f5f5;

			span {
				color: $white;
			}
		}
	}
}

.aboutme-container {
	p {
		color: #afafbf;
		color: $darker-white;

		span {
			color: $white;
			color: $second-white;
		}
	}
}

ul {
	li {
		color: $darker-white;
	}
}

footer {
	color: $dark-white;
}
