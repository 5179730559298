#ProjectModal {
	#ProjectModal-btn {
		width: 332px;

		@media (max-width: $sm-mobile) {
			width: 220px;
			height: 170px;
		}

		@media (max-width: $tablet) {
			width: 270px;
			height: 180px;
		}

		margin-bottom: 2rem;

		button {
			margin: 0;
			padding: 0;
		}
	}
}

#project-info {
	padding: 2rem;

	.project-title {
		text-transform: uppercase;
		margin: 1rem 0 2rem;
		font-size: 0.9rem;
	}
	#img-project {
		padding-bottom: -0.5rem;

		img {
			width: 100%;
			height: 440px;
			object-fit: cover;

			@media (max-width: $mobile) {
				height: 300px;
			}

			@media (max-width: $tablet) {
				height: 230px;
			}

			@media (max-width: $sm-mobile) {
				height: 190px;
			}
			@media (max-width: $extra-sm) {
				height: 170px;
			}
		}
		video {
			width: 100%;
			height: 440px;

			object-fit: cover;
			border-radius: 4px;

			@media (max-width: $mobile) {
				height: 300px;
			}

			@media (max-width: $tablet) {
				height: 230px;
			}

			@media (max-width: $sm-mobile) {
				height: 190px;
			}
			@media (max-width: $extra-sm) {
				height: 170px;
			}
		}
	}

	.single-project-description {
		margin-top: 2rem;
		@media (max-width: $tablet) {
			margin-top: 1.5rem;
		}

		p {
			font-size: 0.9rem;
			font-weight: lighter;
			margin-bottom: 2.5rem;

			@media (max-width: $tablet) {
				font-size: 0.8rem;
			}
		}

		.subtitle {
			margin-bottom: 1.5rem;
		}

		.description {
			margin-bottom: 2rem;

			@media (max-width: $tablet) {
				margin-bottom: 1.5rem;
			}

			.role {
				margin-bottom: 0.5rem;
				@media (max-width: $tablet) {
					margin-bottom: 0.2rem;
				}
			}
		}

		h3 {
			text-transform: uppercase;
			font-size: 1rem;
			margin-bottom: 0.5rem;
			color: $second-white;

			@media (max-width: $tablet) {
				font-size: 0.9rem;
			}
		}

		.tec-use {
			margin-bottom: 2rem;
			h3 {
				margin-bottom: 0.5rem 0;
				color: $second-white;
				font-size: 1rem;

				@media (max-width: $tablet) {
					font-size: 0.9rem;
				}
			}

			p {
				margin-bottom: 0.5rem;
				span {
					margin-right: 0.5rem;
					font-weight: normal;
					color: $white;
				}
			}
		}

		.page-btns {
			a {
				padding: 0.2rem 1rem;
				font-size: 0.9rem;
				font-weight: lighter;
				border: 1px solid $green;
				color: $green;
				border-radius: 4px;
				text-transform: capitalize;

				transition: background 0.4s ease;

				&:hover {
					background: rgba(100, 255, 218, 0.1);
				}

				&:first-child {
					margin-right: 0.5rem;
				}

				&:visited {
					color: $dark-white;
					color: $green;
				}

				@media (max-width: $tablet) {
					font-size: 0.8rem;
				}
			}
		}
	}
}

#btn-close {
	@include flex(flex-end);

	padding: 0 2rem 2rem;

	button {
		text-transform: capitalize;
		font-weight: 300;
		border: 1px solid $green;
		border-radius: 4px;
		font-size: 1rem;
		padding: 0.2rem 1rem;
		background: none;
		outline: none;
		cursor: pointer;
		color: $dark-white;
		color: $green;

		transition: background 0.4s ease;

		&:hover {
			background: rgba(100, 255, 218, 0.2);
		}

		@media (max-width: $tablet) {
			font-size: 0.9rem;
		}
		@media (max-width: $mobile) {
			padding: 1rem;
			margin-bottom: auto;
		}
	}
}
