.Content-container {
	max-width: 950px;
	margin: 0 auto;
	padding: 0 1rem;

	@media (max-width: $mobile) {
		padding: 0 3rem;
	}

	@media (max-width: $tablet) {
		margin-top: -10rem;
	}
	.title-color {
		color: $blue;
		color: $second-white;

		span {
			color: $green;
			margin-right: 0.5rem;
			font-weight: normal;
		}
	}

	h3 {
		text-transform: uppercase;
		font-size: 1.2rem;
		margin-bottom: 0.7rem;
		@media (max-width: $extra-sm) {
			font-size: 0.9rem;
		}
	}

	.aboutme-container {
		@include flex(space-between);
		margin-bottom: 12rem;

		@media (max-width: $lg-mobile) {
			flex-direction: column;
		}

		@media (max-width: $tablet) {
			margin-top: 3rem;
		}

		@media (max-width: $sm-mobile) {
			margin-bottom: 8rem;
			margin-top: 13rem;
		}

		p {
			width: 80%;
			font-size: 1.1rem;
			font-weight: lighter;
			line-height: 1.7;

			@media (max-width: $lg-mobile) {
				width: 85%;
				margin: 1rem auto 0;
			}

			span {
				font-weight: bold;
			}
		}
	}
	.Content-skills {
		@include flex(space-between);

		@media (max-width: $lg-mobile) {
			flex-direction: column;
		}

		.skills-container {
			width: 80%;

			@media (max-width: $lg-mobile) {
				width: 85%;
				margin: 1rem auto 0;
			}

			.shared-width {
				@media (max-width: $lg-mobile) {
					width: 45%;
				}
			}

			.languages,
			.frameworks {
				@media (max-width: $lg-mobile) {
					margin-bottom: 1rem;
				}
			}

			.languages-wrapper {
				@include flex(space-between);
				margin-bottom: 12rem;

				@media (max-width: $lg-mobile) {
					flex-wrap: wrap;
				}

				@media (max-width: $sm-mobile) {
					margin-bottom: 8rem;
				}

				li {
					font-size: 1rem;
					font-weight: lighter;
					margin-bottom: 0.3rem;
				}
			}
		}
	}

	.ProjectContent {
		@include flex(space-between);
		margin-bottom: 12rem;

		@media (max-width: $lg-mobile) {
			flex-direction: column;
		}

		@media (max-width: $sm-mobile) {
			margin-bottom: 8rem;
		}

		.ProjectContent-display {
			width: 80%;
			@include flex(space-between);
			flex-wrap: wrap;

			@media (max-width: $lg-mobile) {
				width: 85%;
				margin: 1rem auto 0;
			}
			.side-one {
				position: relative;
				cursor: pointer;
				margin-bottom: -0.4rem;

				img {
					width: 100%;
					height: 200px;
					object-fit: cover;
					border-radius: 4px;

					@media (max-width: $sm-mobile) {
						width: 220px;
					}
				}

				.toggle-name {
					position: absolute;
					top: 0;
					background: rgba(255, 77, 0, 0.7);
					width: 100%;
					height: 97%;
					padding: 0.7rem 0;
					text-transform: capitalize;
					opacity: 0;
					border-radius: 4px;
					transition: opacity 0.3s ease;
					@include flex(center);
					align-items: center;
					color: #050e1a;
					font-size: 1rem;
					text-transform: uppercase;
				}
				&:hover {
					.toggle-name {
						opacity: 1;
					}
				}
			}
		}
	}
}
